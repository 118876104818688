import { DateTime } from 'luxon'
import { Component } from 'vue-property-decorator'

import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import {
  CalendarEntityType, CalendarPageExerciseResource, CalendarPageLessonResource, CalendarPageMaterialResource,
  CourseType, ManagerCalendarGetParams,
  MasterEducationCalendarGetParams,
  NameValueResource,
} from '@/store/types/schema'
import MasterCalendarModule from '@/store/modules/master/calendar'
import MentorCalendarModule from '@/store/modules/mentor/calendar'
import SystemModule from '@/store/modules/system'
import {
  CalendarLargeItemResource,
  ManagerMasterGroupsMasterGroupIdCalendarGetParams,
  MasterEducationMasterGroupIdCalendarGetParams,
} from '@/store/types'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import { formatDate, parseDateToMilliseconds } from '@/utils/functions'
import { subjectsColor, subjectsListOnID } from '@/utils/constants'

enum ScheduleMarks {
  LESSONS = 'LESSONS',
  EXERCISES = 'EXERCISES',
  PERSONALS = 'PERSONALS',
  WEBINARS = 'WEBINARS',
  MATERIALS = 'MATERIALS',
}

enum MenuActions {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
}

export enum EventType {
  LESSON = 'lesson',
  EXERCISE = 'exercise',
  USEFUL_MATERIAL = 'useful_material',
}

@Component
export default class CalendarMixin extends MasterGroupMixin {
  private get currentTime() {
    return DictionaryModule.currentTime
  }

  /////////////////////////////////////
  // Note: Общие данные для малого календаря
  /////////////////////////////////////
  public get calendar() {
    if (SystemModule.interface === 'manager') {
      return MentorCalendarModule.calendar
    } else {
      return MasterCalendarModule.calendar
    }
  }

  /////////////////////////////////////
  // Note: Общие данные для большого календаря
  /////////////////////////////////////
  public get fullCalendar() {
    if (SystemModule.interface === 'manager') {
      return MentorCalendarModule.fullCalendar
    } else {
      return MasterCalendarModule.fullCalendar
    }
  }

  /////////////////////////////////////
  // Note: Для "малого" календаря
  /////////////////////////////////////

  // Функция нужна для того, чтобы понимать есть ли домашки ещё в этот день,
  // в котором есть и персональный урок и домашки
  private _isAnyExserciseOnThisDay(exercise: CalendarLargeItemResource, dayInfo: [number, number, number]) {
    return this.calendar?.exercise.some(item => {
      if (this._isShouldBeMarked(item, dayInfo) && exercise.exerciseAccessLevel !== item.exerciseAccessLevel) {
        return true
      }
      return false
    })

  }

  private _isAnyLessonOnThisDay(lesson: CalendarLargeItemResource, dayInfo: [number, number, number]) {
    return this.calendar?.lesson.some(item => {
      if (this._isShouldBeMarked(item, dayInfo) && lesson.isWebinar !== item.isWebinar) {
        return true
      }
      return false
    })
  }

  public handleEvents(date: string) {
    const [year, month, day] = date.split('-').map(item => +item)
    const eventsClasses: string[] = []

    let isLessonShouldBeMarked = false

    if (this.calendar) {
      this.calendar.lesson.some(item => {
        isLessonShouldBeMarked = this._isShouldBeMarked(item, [day, month, year])

        if (isLessonShouldBeMarked) {
          /*if (item.isWebinar && !eventsClasses.includes('webinars'))
            eventsClasses.push('webinars')*/
          if (!item.isWebinar && !eventsClasses.includes('lessons'))
            eventsClasses.push('lessons')
        }
      })

      this.calendar.exercise.some(item => {
        isLessonShouldBeMarked = this._isShouldBeMarked(item, [day, month, year])

        if (isLessonShouldBeMarked) {
          if (!eventsClasses.includes('exercises')) {
            eventsClasses.push('exercises')
          }
        }
      })

      this.calendar.usefulMaterial.some(item => {
        isLessonShouldBeMarked = this._isShouldBeMarked(item, [day, month, year])

        if (isLessonShouldBeMarked) {
          if (!eventsClasses.includes('material'))
            eventsClasses.push('material')
        }
      })
      return eventsClasses
    }

    else return false
  }

  private _isShouldBeMarked(item: CalendarLargeItemResource, date: number[]) {
    const _day = DateTime.fromSQL(item.type === CalendarEntityType.EXERCISE ? item.deadlineAt : item.spendingAt).startOf('day').toJSDate().getDate()
    const _month = DateTime.fromSQL(item.type === CalendarEntityType.EXERCISE ? item.deadlineAt : item.spendingAt).startOf('day').toJSDate().getMonth() + 1
    const _year = DateTime.fromSQL(item.type === CalendarEntityType.EXERCISE ? item.deadlineAt : item.spendingAt).startOf('day').toJSDate().getFullYear()
    return Boolean(date[0] === _day && date[1] === _month && date[2] === _year)
  }

  public handleUpdatePickerDate(date: string): ManagerMasterGroupsMasterGroupIdCalendarGetParams | MasterEducationMasterGroupIdCalendarGetParams {
    const year = +date.substring(0, 4)
    const month = +date.substring(5, 8)

    const endAt = (new Date(year, month, 1)).toISOString().substring(0, 10)
    const startAt = date.toString() + '-01'

    return { endAt, startAt }
  }

  /////////////////////////////////////
  // Note: Для "большого" календаря
  /////////////////////////////////////
  private get startAt() {
    return SystemModule.interface === 'master' ? MasterCalendarModule.calendarFilter.startAt : MentorCalendarModule.calendarFilter.startAt
  }
  // Этот геттер нужен для запоминания фильтра
  private get filterMonth() {
    if (!this.startAt) {
      return this.calendarToday
    }
    const [ year, month, day ] = this.startAt.split('-')

    if (day === '01') {
      return `${year}-${month}-01`
    }

    if (month === '12') {
      return `${+year + 1}-01-01`
    }

    return `${year}-${+month + 1}-01`
  }

  // Когда title не отображается показывать этот заголовок
  private get calendarTitle() {
    return DateTime.now().toFormat('LLLL yyyy')
  }

  private focus = this.filterMonth
  public currentChosenOption = this.menu[0]

  protected get scheduleMarks(): NameValueResource[] {
    const baseScheduleMarks = [
      { name: 'Урок', value: ScheduleMarks.LESSONS },
      { name: 'Задание', value: ScheduleMarks.EXERCISES },
      { name: 'Полезно', value: ScheduleMarks.MATERIALS },
      { name: 'Завершено', value: 'ended' },
    ]
    return baseScheduleMarks
  }

  public get menu () {
    const list = [
      { name: 'Месяц', value: MenuActions.MONTH },
    ]

    if (this.$vuetify.breakpoint.name === 'xs') {
      list.push({ name: 'День', value: MenuActions.DAY })
    } else {
      list.push({ name: 'Неделя', value: MenuActions.WEEK })
    }

    return list
  }

  public get weekdays() {
    return [1, 2, 3, 4, 5, 6, 0]
  }

  public get dateRaw() {
    return DictionaryModule.currentTimeRaw
  }

  public get calendarToday() {
    return this.dateRaw.slice(0, 10)
  }

  public get exerciseStatuses() {
    return DictionaryModule.exerciseStatusesFilter
  }

  public get lessonStatuses() {
    return DictionaryModule.lessonStatusesFilter
  }

  public formatHours(date: any) {
    return date.time
  }

  public fetchCalendar(masterGroupID: number, filter: MasterEducationMasterGroupIdCalendarGetParams | ManagerMasterGroupsMasterGroupIdCalendarGetParams) {
    if (SystemModule.interface === 'master') {
      return MasterCalendarModule.fetchCalendar({ masterGroupId: masterGroupID, params: filter })
    } else {
      return MentorCalendarModule.fetchCalendar({ masterGroupId: masterGroupID, params: filter })
    }
  }

  public fetchMasterFullCalendar(payload: MasterEducationCalendarGetParams) {
    return MasterCalendarModule.fetchFullCalendar(payload)
  }

  public fetchMentorFullCalendar(payload: ManagerCalendarGetParams) {
    return MentorCalendarModule.fetchFullCalendar(payload)
  }

  public get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  public get largeFullCalendarEvents() {
    const events: any[] = []

    if (this.fullCalendar) {
      this.fullCalendar.lesson.forEach((item: CalendarPageLessonResource) => {
        const start = formatDate(item.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone)
        const color = subjectsColor[subjectsListOnID[item.subjectId]]
        const end = DateTime.fromSQL(start).plus({ hours: 1 }).toFormat('yyyy-MM-dd HH:mm:ss')

        // Если событие доступно, но еще не наступило
        const noStart = parseDateToMilliseconds(item.spendingAt) > this.currentTime && SystemModule.interface === 'master'

        const result: any = {
          color: `v-event_${color}${item.isAvailable ? '' : ' no-available'}${item.isPassed ? ' is-passed' : ''}${noStart ? ' no-start' : ''}`,
          colorIcon: item.isAvailable ? color : 'teal darken-4',
          end,
          icon: '$courses',
          id: item.id,
          isAvailable: item.isAvailable,
          masterGroup: item.masterGroup,
          name: item.title,
          noStart,
          start,
          time: formatDate(start, 'HH:mm'),
          type: item.type,
          typeLabel: 'Уроки',
        }

        events.push(result)
      })

      this.fullCalendar.exercise.forEach((item: CalendarPageExerciseResource) => {
        const start = formatDate(item.deadlineAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone)
        const color = subjectsColor[subjectsListOnID[item.subjectId]]
        const end = DateTime.fromSQL(start).plus({ hours: 1 }).toFormat('yyyy-MM-dd HH:mm:ss')

        // Если событие доступно, но еще не наступило
        const noStart = parseDateToMilliseconds(item.spendingAt) > this.currentTime && SystemModule.interface === 'master'

        const result: any = {
          color: `v-event_${color}${item.isAvailable ? '' : ' no-available'}${item.isPassed ? ' is-passed' : ''}${noStart ? ' no-start' : ''}`,
          colorIcon: item.isAvailable ? color : 'teal darken-4',
          courseType: item.courseType,
          end,
          icon: '$edit',
          id: item.id,
          isAvailable: item.isAvailable,
          masterGroup: item.masterGroup,
          name: item.title,
          noStart,
          start,
          tasks: item.tasks,
          time: formatDate(start, 'HH:mm'),
          type: item.type,
          typeLabel: 'Задание',
          uuid: item.uuid,
        }

        events.push(result)
      })

      this.fullCalendar.usefulMaterial.forEach((item: CalendarPageMaterialResource) => {
        const start = formatDate(item.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone)
        const color = subjectsColor[subjectsListOnID[item.subjectId]]
        const end = DateTime.fromSQL(start).plus({ hours: 1 }).toFormat('yyyy-MM-dd HH:mm:ss')

        // Если событие доступно, но еще не наступило
        const noStart = parseDateToMilliseconds(item.spendingAt) > this.currentTime && SystemModule.interface === 'master'

        const result: any = {
          color: `v-event_${color}${item.isAvailable ? '' : ' no-available'}${item.isPassed ? ' is-passed' : ''}${noStart ? ' no-start' : ''}`,
          colorIcon: item.isAvailable ? color : 'teal darken-4',
          courseID: item.courseId,
          end,
          icon: '$star',
          id: item.id,
          isAvailable: item.isAvailable,
          name: item.title,
          noStart,
          start,
          time: formatDate(start, 'HH:mm'),
          type: item.type,
          typeLabel: 'Полезное',
        }

        events.push(result)
      })
    }

    return events
  }

  // Метод клика по событию календаря
  public handleCalendarEventClick(eventInfo: any) {
    const { event } = eventInfo

    if (event.type === EventType.LESSON) {
      const start = DateTime.fromSQL(event.start).toFormat('yyyy-MM')
      const now = DateTime.fromSQL(this.dateRaw).toFormat('yyyy-MM')
      if (SystemModule.interface === 'manager') {
        this.$router.push({ name: 'manager.education.lessons.item', params: { groupID: event.masterGroup.value, lessonID: event.id } })
          .catch(() => {return})
      } else {
        if (!event.isAvailable) {
          if (start >= now)
            this.openProlongationCourse(event.masterGroup.value)
          return
        }
        if (event.noStart) {
          return
        }
        this.$router.push({ name: 'master.lessons.item', params: { groupID: event.masterGroup.value, lessonID: event.id } })
          .catch(() => {return})
      }
    } else if (event.type === EventType.EXERCISE) {
      const start = DateTime.fromSQL(event.start).toFormat('yyyy-MM')
      const now = DateTime.fromSQL(this.dateRaw).toFormat('yyyy-MM')
      if (SystemModule.interface === 'manager') {
        event.tasks.length ?
          this.$router.push({ name: event.courseType === CourseType.SPECIAL ? 'manager.education.exercises.quickStart' : 'manager.education.exercises.item.masterList', params: { exerciseUUID: event.uuid, groupID: event.masterGroup.value, taskUUID: event.tasks[0].value } }).catch(() => {return}) :
          this.$router.push({ name: 'manager.education.exercises.item.edit', params: { exerciseUUID: event.uuid, groupID: event.masterGroup.value } })
            .catch(() => {return})
      } else {
        if (!event.isAvailable) {
          if (start >= now)
            this.openProlongationCourse(event.masterGroup.value)
          return
        }
        if (event.noStart) {
          return
        }
        this.$router.push({ name: 'master.exercises.item', params: { exerciseUUID: event.uuid, groupID: event.masterGroup.value } })
          .catch(() => {return})
      }
    } else if (event.type === EventType.USEFUL_MATERIAL) {
      const start = DateTime.fromSQL(event.start).toFormat('yyyy-MM')
      const now = DateTime.fromSQL(this.dateRaw).toFormat('yyyy-MM')
      if (SystemModule.interface === 'master') {
        if (!event.isAvailable) {
          if (start >= now)
            this.openProlongationCourse(event.courseID)
          return
        }
        if (event.noStart) {
          return
        }
        this.$router.push({ name: 'master.depository.item.materials', params: { courseID: event.courseID }, query: { endDate: formatDate(event.start, 'yyyy-MM-dd'), startDate: formatDate(event.start, 'yyyy-MM-dd') } })
          .catch(() => {return})
      } else {
        this.$router.push({ name: 'manager.bank.depository.courses.item.materials.view', params: { courseID: event.courseID, materialID: event.id.toString() } })
          .catch(() => {return})
      }
    }
  }

  public weekLabelDecider(date: string) {
    return DateTime.fromSQL(date, { zone: 'Europe/Moscow' }).weekdayShort
  }

  private openProlongationCourse(masterGroupID: number) {
    const course = this.masterGroupsFull.find(mg => mg.id === masterGroupID)
    if (course) {
      this.$bus.$emit('show-prolongation-modal', course.course.id)
    }
  }
}
